import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout>
      <h1>Home</h1>
      <h2>Hallo, ich bin Philipp, ein Tester der Tests</h2>
      <p>
        Gatsby-Link <Link to="/contact">Contact me.</Link>
      </p>
    </Layout>
  )
}

export default IndexPage
